<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-text>
          <b-row>
            <b-col
              cols="12"
            >
              <h6 class="mb-1">
                Veri Girişi
              </h6>
            </b-col>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group label="Marka">
                <v-select
                  v-model="formData.id_com_brand"
                  :options="brands"
                  label="title"
                  :reduce="brand => brand.id"
                  placeholder="Marka"
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group label="Lokasyon">
                <v-select
                  v-model="formData.id_com_location"
                  :options="locations"
                  label="title"
                  :reduce="location => location.id"
                  placeholder="Lokasyon"
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group label="Başlangıç Tarihi">
                <b-form-datepicker
                  v-model="formData.sdate"
                  v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group label="Bitiş Tarihi">
                <b-form-datepicker
                  v-model="formData.edate"
                  v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Mekanik Giriş Adedi">
                <cleave
                  id="mechanical"
                  v-model="formData.mechanical"
                  class="form-control"
                  :raw="false"
                  :options="options.count"
                  placeholder="Mekanik Giriş Adedi"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Hasar Giriş Adedi">
                <cleave
                  id="damage"
                  v-model="formData.damage"
                  class="form-control"
                  :raw="false"
                  :options="options.count"
                  placeholder="Hasar Giriş Adedi"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
      <b-button
        variant="primary"
        @click="submitForm"
      >
        Kaydet
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardText, BRow, BCol, BButton, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Edit',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BButton,
    vSelect,
    Cleave,
  },
  data() {
    return {
      submitStatus: false,
      options: {
        count: {
          numeral: true,
          delimiter: '',
        },
      },
      formData: {
        id_com_brand: null,
        id_com_location: null,
        sdate: null,
        edate: null,
        mechanical: null,
        damage: null,
      },
    }
  },
  computed: {
    counterData() {
      return this.$store.getters['serviceCounters/getService_counter']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    saveStatus() {
      return this.$store.getters['serviceCounters/getService_counterSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
    counterData(val) {
      this.formData = val
    },
  },
  created() {
    this.getData()
    this.getBrands()
    this.getLocations()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getData() {
      this.$store.dispatch('serviceCounters/service_counterView', this.$route.params.id)
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.servicestatus': 1,
        },
      })
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: ['com_location.id AS id', 'com_location.title AS title'],
        where: {
          'com_location.service_status': 1,
        },
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('serviceCounters/service_counterSave', this.formData)
    },
  },
}
</script>
